var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.$vuetify.breakpoint.smAndDown || _vm.maximized,
        persistent: "",
        scrollable: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "v-card",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "fill-height pb-0 mb-0",
                        class: Object.assign(
                          {},
                          {
                            "py-10 px-4": _vm.hasPadding,
                            "card-border": _vm.hasBorder,
                            "card-border-primary":
                              _vm.hasBorder && _vm.borderColor === 1,
                            "card-border-secondary":
                              _vm.hasBorder && _vm.borderColor === 2,
                            "card-border-accent":
                              _vm.hasBorder && _vm.borderColor === 3
                          },
                          _vm.cardClass
                        ),
                        style: Object.assign({}, _vm.cardStyle, {
                          opacity: _vm.opacity
                        }),
                        attrs: { tile: "" }
                      },
                      "v-card",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  ),
                  [
                    !_vm.noMaximize || !_vm.noTitle
                      ? _c(
                          "v-toolbar",
                          {
                            attrs: {
                              color: _vm.toolbarColor1,
                              dark: _vm.toolbarDark1,
                              dense: "",
                              flat: ""
                            },
                            on: {
                              dblclick: function($event) {
                                _vm.expandable
                                  ? (_vm.expanded = !_vm.expanded)
                                  : true
                              }
                            }
                          },
                          [
                            !_vm.noTitle && _vm.title && _vm.title.length > 0
                              ? _c(
                                  "v-toolbar-title",
                                  [
                                    _vm._t(
                                      "title",
                                      [_vm._v(_vm._s(_vm.title))],
                                      { title: _vm.title }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.noMaximize ? _c("v-spacer") : _vm._e(),
                            _vm._t("toolbar-actions"),
                            !_vm.noMaximize
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var ton = ref.on
                                            return [
                                              _vm.expandable
                                                ? _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          "aria-label":
                                                            "Expand/Collapse Card",
                                                          icon: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.expanded = !_vm.expanded
                                                          }
                                                        }
                                                      },
                                                      ton
                                                    ),
                                                    [
                                                      _vm.expanded
                                                        ? _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-chevron-up"
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      !_vm.expanded
                                                        ? _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-chevron-down"
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm.expanded
                                      ? _c("span", [_vm._v("Collapse")])
                                      : _vm._e(),
                                    !_vm.expanded
                                      ? _c("span", [_vm._v("Expand")])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            !_vm.noMaximize
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var ton = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      "aria-label":
                                                        "Maximize Card",
                                                      icon: ""
                                                    }
                                                  },
                                                  Object.assign({}, on, ton)
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-arrow-expand")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Maximize")])]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.expanded
                      ? _c(
                          "v-card-text",
                          {
                            staticClass: "pb-0 mb-0 pt-0 mt-0",
                            class: { "pl-0 pr-0": _vm.extended }
                          },
                          [_vm._t("default")],
                          2
                        )
                      : _vm._e(),
                    _vm._t("actions")
                  ],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      !_vm.noMaximize
        ? _c(
            "v-card",
            _vm._g(
              _vm._b(
                {
                  staticClass: "fill-height pb-0 mb-0",
                  class: { "py-10 px-4": _vm.hasPadding },
                  staticStyle: { opacity: "100%" },
                  attrs: { tile: "" }
                },
                "v-card",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              !_vm.noMaximize || !_vm.noTitle
                ? _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: _vm.toolbarColor2,
                        dark: _vm.toolbarDark2,
                        flat: "",
                        height: "56",
                        "max-height": "56"
                      }
                    },
                    [
                      !_vm.noTitleOnExpanded &&
                      _vm.title &&
                      _vm.title.length > 0
                        ? _c(
                            "v-toolbar-title",
                            [
                              _vm._t("title", [_vm._v(_vm._s(_vm.title))], {
                                title: _vm.title
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      !_vm.noMaximize ? _c("v-spacer") : _vm._e(),
                      _vm._t("toolbar-actions"),
                      !_vm.noMaximize
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var ton = ref.on
                                      return [
                                        !_vm.noMaximize
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    "aria-label":
                                                      "Collapse Card",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.dialog = false
                                                    }
                                                  }
                                                },
                                                ton
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-arrow-collapse")
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1175643075
                              )
                            },
                            [_c("span", [_vm._v("Collapse")])]
                          )
                        : _vm._e(),
                      !_vm.noMaximize
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var ton = ref.on
                                      return [
                                        !_vm.noMaximize &&
                                        !_vm.$vuetify.breakpoint.smAndDown
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    "aria-label":
                                                      "Minimize/Maximize Window",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      if (
                                                        $event.ctrlKey ||
                                                        $event.shiftKey ||
                                                        $event.altKey ||
                                                        $event.metaKey
                                                      ) {
                                                        return null
                                                      }
                                                      _vm.maximized = !_vm.maximized
                                                    }
                                                  }
                                                },
                                                ton
                                              ),
                                              [
                                                _vm.maximized
                                                  ? _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-window-minimize"
                                                      )
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-window-maximize"
                                                      )
                                                    ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                345639468
                              )
                            },
                            [
                              !_vm.maximized
                                ? _c("span", [_vm._v("Maximize")])
                                : _vm._e(),
                              _vm.maximized
                                ? _c("span", [_vm._v("Minimize")])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                {
                  staticClass: "pb-0 mb-0 pt-0 mt-0",
                  class: { "pl-0 pr-0": _vm.extended }
                },
                [_vm._t("default")],
                2
              ),
              _vm._t("actions")
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }