import { render, staticRenderFns } from "./_asyent-form-textarea.vue?vue&type=template&id=701ff8ce&scoped=true&"
import script from "./_asyent-form-textarea.vue?vue&type=script&lang=js&"
export * from "./_asyent-form-textarea.vue?vue&type=script&lang=js&"
import style0 from "./_asyent-form-textarea.vue?vue&type=style&index=0&id=701ff8ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "701ff8ce",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-master\\asyhub-maritime-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('701ff8ce')) {
      api.createRecord('701ff8ce', component.options)
    } else {
      api.reload('701ff8ce', component.options)
    }
    module.hot.accept("./_asyent-form-textarea.vue?vue&type=template&id=701ff8ce&scoped=true&", function () {
      api.rerender('701ff8ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/_asyent-form-textarea.vue"
export default component.exports