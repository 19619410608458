var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: _vm.reference + "observer",
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var errorsClient = ref.errors
            return [
              _c(
                "v-form",
                {
                  ref: _vm.reference,
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.formValid,
                    callback: function($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid"
                  }
                },
                [
                  _vm.isFormSubmitting
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm._t(
                                "formSubmitting",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticStyle: { opacity: "97%" },
                                      attrs: {
                                        dense: "",
                                        elevation: "",
                                        tile: "",
                                        type: "success"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          attrs: {
                                            color: "transparent",
                                            dense: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { dense: "" } },
                                            [
                                              _vm._v(
                                                " Your request is processing, please wait and do NOT refresh or navigate away. "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                { formSuccess: _vm.success }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.success && _vm.success.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm._t(
                                "formSuccess",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticStyle: { opacity: "97%" },
                                      attrs: {
                                        border: "left",
                                        "colored-border": "",
                                        dense: "",
                                        elevation: "",
                                        tile: "",
                                        type: "success"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          attrs: {
                                            color: "transparent",
                                            dense: ""
                                          }
                                        },
                                        _vm._l(_vm.success, function(
                                          message,
                                          i
                                        ) {
                                          return _c(
                                            "v-list-item",
                                            { key: i, attrs: { dense: "" } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(message) + " "
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                { formSuccess: _vm.success }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formCombinedErrors(errorsClient) &&
                  _vm.formCombinedErrors(errorsClient).length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _vm._t(
                                "formErrors",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticStyle: { opacity: "97%" },
                                      attrs: {
                                        border: "left",
                                        "colored-border": "",
                                        dense: "",
                                        elevation: "1",
                                        tile: "",
                                        type: "error"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          attrs: {
                                            color: "transparent",
                                            dense: ""
                                          }
                                        },
                                        _vm._l(
                                          _vm.formCombinedErrors(errorsClient),
                                          function(message, i) {
                                            return _c(
                                              "v-list-item",
                                              { key: i, attrs: { dense: "" } },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(message) + " "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                { formErrors: errorsClient }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataLoading || _vm.dataError
                    ? [
                        _vm._t("formDataLoading", [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                color:
                                  "grey " +
                                  (_vm.$vuetify.theme.dark
                                    ? "darken-2"
                                    : "lighten-4")
                              }
                            },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "mx-auto",
                                attrs: { elevation: "0", type: "card-avatar" }
                              }),
                              _c("v-skeleton-loader", {
                                staticClass: "mx-auto",
                                attrs: { elevation: "0", type: "actions" }
                              }),
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _vm.dataError
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center text-truncate text-no-wrap"
                                        },
                                        [
                                          _vm._v(
                                            "Unable to load data, please try again by refreshing this page"
                                          )
                                        ]
                                      )
                                    : _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center text-truncate text-no-wrap"
                                        },
                                        [_vm._v("Loading... please wait!")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    : [
                        _vm._t("default", null, {
                          clearable: _vm.editable,
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          readonly: _vm.readonly,
                          submitted: _vm.isFormSubmitted,
                          valid: _vm.formValid
                        }),
                        _vm._t(
                          "formActionsList",
                          [
                            !_vm.dataLoading && !_vm.dataError && _vm.hasActions
                              ? [
                                  _c(
                                    "v-list",
                                    {
                                      attrs: {
                                        color: "transparent",
                                        flat: "",
                                        subheader: ""
                                      }
                                    },
                                    [
                                      _vm._t("formActionsHeader", [
                                        _c("v-subheader", [
                                          _vm._v(_vm._s(_vm.formActionsTitle))
                                        ])
                                      ]),
                                      _vm._l(
                                        _vm.formActionsFiltered.filter(
                                          _vm.isAuthorized
                                        ),
                                        function(action) {
                                          return _c(
                                            "v-list-item",
                                            { key: action.name },
                                            [
                                              _c("asyent-button", {
                                                attrs: {
                                                  "button-icon": action["icon"],
                                                  "button-tooltip":
                                                    action["tooltip"],
                                                  "requires-confirmation":
                                                    action["action"],
                                                  small: "",
                                                  text: ""
                                                },
                                                on: {
                                                  confirmed: function($event) {
                                                    return _vm.formActionMethod2(
                                                      action
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]
                              : _vm._e()
                          ],
                          { formActionsFiltered: _vm.formActionsFiltered }
                        )
                      ],
                  _c(
                    "v-bottom-sheet",
                    {
                      attrs: {
                        scrollable: "",
                        "max-width": _vm.$vuetify.breakpoint.mdAndDown
                          ? "100vw"
                          : "50vw"
                      },
                      model: {
                        value: _vm.successDialog,
                        callback: function($$v) {
                          _vm.successDialog = $$v
                        },
                        expression: "successDialog"
                      }
                    },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "text-center",
                          attrs: { color: "secondary" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto pa-6",
                              attrs: { color: "secondary", flat: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { large: "", left: "" } },
                                    [_vm._v(" mdi-check ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-h6 font-weight-regular"
                                    },
                                    [_vm._v("Document Verification")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "text-h6 text-justify font-weight-regular mx-auto"
                                },
                                [
                                  _vm._v(
                                    " Basic document verifications have been passed successfully, you may proceed. "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }