var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      attrs: { persistent: "", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var sheetOn = ref.on
            return [
              _vm.requiresConfirmation
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          "aria-label": _vm.buttonTooltip
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            _vm.dialog = true
                                          }
                                        }
                                      },
                                      "v-btn",
                                      _vm.$attrs,
                                      false
                                    ),
                                    Object.assign({}, on, sheetOn)
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          left: !_vm.hideTooltip && _vm.text
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.buttonIcon))]
                                    ),
                                    !_vm.icon && !_vm.hideTooltip
                                      ? _c(
                                          "span",
                                          {
                                            class: _vm.textClass,
                                            staticStyle: {
                                              "font-size": "0.8125rem"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.buttonTooltip))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.buttonTooltip))])]
                  )
                : _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          "aria-label": _vm.buttonTooltip,
                                          small: _vm.small
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.confirm($event)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      _vm.$attrs,
                                      false
                                    ),
                                    Object.assign({}, _vm.$listeners, on)
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          left: !_vm.hideTooltip && _vm.text
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.buttonIcon))]
                                    ),
                                    !_vm.icon && !_vm.hideTooltip
                                      ? _c(
                                          "span",
                                          {
                                            class: _vm.textClass,
                                            staticStyle: {
                                              "font-size": "0.8125rem"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.buttonTooltip))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.buttonTooltip))])]
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-sheet",
        { staticClass: "text-center", attrs: { color: "secondary" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto pa-6",
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.mdAndDown
                  ? "100vw"
                  : "50vw",
                color: "secondary",
                flat: ""
              }
            },
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v(" mdi-information-outline ")
                  ]),
                  _c("span", { staticClass: "text-h6 font-weight-regular" }, [
                    _vm._v("Confirmation")
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass:
                    "text-h6 text-justify font-weight-regular mx-auto"
                },
                [
                  _vm._v(" You are about to perform "),
                  _c("span", { staticClass: "font-italic" }, [
                    _vm._v("'" + _vm._s(_vm.buttonTooltip) + "'")
                  ]),
                  _vm._v(". Are you sure you want to proceed? ")
                ]
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "aria-label": "Cancel",
                        elevation: "0",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.closeDialog($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-cancel")
                      ]),
                      _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v("Cancel")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          "aria-label": "Proceed",
                          elevation: "0",
                          text: ""
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.confirm($event)
                          }
                        }
                      },
                      _vm.$listeners
                    ),
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-check")
                      ]),
                      _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v("Proceed")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }