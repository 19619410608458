var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-card",
    _vm._b(
      {
        staticClass: "fill-height",
        attrs: {
          color: _vm.bgColor,
          dark: _vm.bgColorDark,
          "no-title": !_vm.showLabel,
          title: _vm.label,
          "no-maximize": ""
        }
      },
      "asyent-card",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { justify: "space-between" } },
        [
          _c("v-col", { staticClass: "text-left" }, [
            _c("span", {
              staticClass: "text-h2 font-weight-light",
              domProps: { textContent: _vm._s(_vm.value2) }
            }),
            _c("span", { staticClass: "subheading font-weight-light mr-1" }, [
              _vm._v(_vm._s(_vm.unit))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-slider",
        _vm._b(
          {
            attrs: {
              color: _vm.sliderColor,
              disabled:
                _vm.disabled ||
                _vm.props.disabled ||
                _vm.isDisabledFunc(_vm.name),
              max: _vm.max,
              min: _vm.min,
              readonly: _vm.readonly || _vm.props.readonly,
              "always-dirty": "",
              "track-color": "trackerColor"
            },
            scopedSlots: _vm._u([
              {
                key: "prepend",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          disabled:
                            _vm.disabled ||
                            _vm.readonly ||
                            _vm.props.disabled ||
                            _vm.props.readonly
                        },
                        on: { click: _vm.decrement }
                      },
                      [_vm._v(" mdi-minus ")]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "append",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          disabled:
                            _vm.disabled ||
                            _vm.readonly ||
                            _vm.props.disabled ||
                            _vm.props.readonly
                        },
                        on: { click: _vm.increment }
                      },
                      [_vm._v(" mdi-plus ")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.value2,
              callback: function($$v) {
                _vm.value2 = $$v
              },
              expression: "value2"
            }
          },
          "v-slider",
          _vm.props,
          false
        )
      ),
      _c("asyent-form-field-error", {
        attrs: { value: _vm.formErrors[_vm.name] }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }