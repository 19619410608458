var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    _vm._b(
      {
        attrs: {
          width: _vm.drawerWidth,
          dense: "",
          nav: "",
          expand: "",
          tile: ""
        }
      },
      "v-list",
      _vm.$attrs,
      false
    ),
    [
      _vm.appConfig.navigation.searchable
        ? _c("v-text-field", {
            staticClass: "pb-3",
            attrs: {
              "background-color": "searchColor",
              "aria-label": "Search Library",
              autofocus: _vm.$vuetify.breakpoint.mdAndUp,
              flat: "",
              "hide-details": "",
              placeholder: "e.g. My Profile",
              solo: "",
              dense: "",
              outlined: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend-inner",
                  fn: function() {
                    return [
                      _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v("mdi-magnify")
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1741733094
            ),
            model: {
              value: _vm.drawerFilter,
              callback: function($$v) {
                _vm.drawerFilter = $$v
              },
              expression: "drawerFilter"
            }
          })
        : _vm._e(),
      _vm._l(_vm.menuLibrary, function(item1stLevel) {
        return [
          item1stLevel["type"] === "divider"
            ? [
                _c("v-subheader", { key: item1stLevel["id"] }, [
                  _vm._v(_vm._s(item1stLevel["name"]))
                ])
              ]
            : item1stLevel["type"] === "folder" && item1stLevel["children"]
            ? _c(
                "v-list-group",
                {
                  key: item1stLevel["id"],
                  attrs: { color: _vm.$vuetify.theme.dark ? "" : "primary" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prependIcon",
                        fn: function() {
                          return [
                            item1stLevel["icon"]
                              ? _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: _vm.renderColor(
                                        item1stLevel,
                                        _vm.color1
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item1stLevel["icon"]) + " "
                                    )
                                  ]
                                )
                              : _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: _vm.renderColor(
                                        item1stLevel,
                                        _vm.color1
                                      )
                                    }
                                  },
                                  [_vm._v(" mdi-folder ")]
                                )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(" " + _vm._s(item1stLevel["name"]))
                                ])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item1stLevel["model"],
                    callback: function($$v) {
                      _vm.$set(item1stLevel, "model", $$v)
                    },
                    expression: "item1stLevel['model']"
                  }
                },
                [
                  _vm._l(item1stLevel["children"], function(item2ndLevel) {
                    return [
                      item2ndLevel["type"] === "folder" &&
                      item2ndLevel["children"]
                        ? _c(
                            "v-list-group",
                            {
                              key: item2ndLevel["id"],
                              staticStyle: { "padding-left": "10px" },
                              attrs: {
                                color: _vm.$vuetify.theme.dark ? "" : "primary"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "prependIcon",
                                    fn: function() {
                                      return [
                                        item2ndLevel["icon"]
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: _vm.renderColor(
                                                    item2ndLevel,
                                                    _vm.color2
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item2ndLevel["icon"]
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: _vm.renderColor(
                                                    item2ndLevel,
                                                    _vm.color2
                                                  )
                                                }
                                              },
                                              [_vm._v(" mdi-folder ")]
                                            )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "activator",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2ndLevel["name"]) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item2ndLevel["model"],
                                callback: function($$v) {
                                  _vm.$set(item2ndLevel, "model", $$v)
                                },
                                expression: "item2ndLevel['model']"
                              }
                            },
                            _vm._l(item2ndLevel["children"], function(
                              item3rdLevel
                            ) {
                              return _c(
                                "v-list-item",
                                _vm._b(
                                  {
                                    key: item3rdLevel["id"],
                                    staticStyle: { "padding-left": "30px" },
                                    attrs: {
                                      color: _vm.$vuetify.theme.dark
                                        ? ""
                                        : "primary",
                                      dense: "",
                                      link: ""
                                    }
                                  },
                                  "v-list-item",
                                  item3rdLevel.target,
                                  false
                                ),
                                [
                                  item3rdLevel["icon"]
                                    ? _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.renderColor(
                                                  item3rdLevel,
                                                  _vm.color3
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item3rdLevel["icon"]) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(item3rdLevel["name"]) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : item2ndLevel["type"] !== "divider"
                        ? _c(
                            "v-list-item",
                            _vm._b(
                              {
                                key: item2ndLevel["id"],
                                staticStyle: { "padding-left": "20px" },
                                attrs: {
                                  color: _vm.$vuetify.theme.dark
                                    ? ""
                                    : "primary",
                                  dense: "",
                                  exact: "",
                                  link: ""
                                }
                              },
                              "v-list-item",
                              item2ndLevel.target,
                              false
                            ),
                            [
                              item2ndLevel["icon"]
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: _vm.renderColor(
                                              item2ndLevel,
                                              _vm.color2
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item2ndLevel["icon"]) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " + _vm._s(item2ndLevel["name"]) + " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _c(
                "v-list-item",
                _vm._b(
                  {
                    key: item1stLevel["id"],
                    attrs: {
                      color: _vm.$vuetify.theme.dark ? "" : "primary",
                      dense: "",
                      exact: "",
                      link: ""
                    }
                  },
                  "v-list-item",
                  item1stLevel.target,
                  false
                ),
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.renderColor(item1stLevel, _vm.color1)
                          }
                        },
                        [_vm._v(_vm._s(item1stLevel["icon"]))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "text-caption" }, [
                        _vm._v(" " + _vm._s(item1stLevel["name"]) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }