var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.viewMode
        ? _c("asyent-form-display", {
            attrs: {
              value: _vm.challengeValue,
              "no-label": _vm.noLabel,
              label: _vm.label
            }
          })
        : _vm._e(),
      !_vm.noLabel && !_vm.viewMode
        ? _c("div", [
            _c("span", { staticStyle: { "font-size": "0.85rem" } }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ])
        : _vm._e(),
      !_vm.viewMode
        ? _c("ValidationProvider", {
            attrs: {
              name: _vm.label,
              rules: _vm.formFieldRules,
              vid: _vm.name
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var errors = ref.errors
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mt-2",
                              attrs: {
                                "aria-label": _vm.label,
                                disabled: _vm.formFieldDisabled,
                                "error-messages": _vm.formFieldErrors(errors),
                                hint:
                                  !_vm.isChallengeDisabled &&
                                  _vm.challengeValue &&
                                  !_vm.readonly &&
                                  !_vm.formFieldDisabled
                                    ? "Click on the shield icon to confirm"
                                    : "",
                                clearable: "",
                                counter: "",
                                label: "",
                                "persistent-hint": "",
                                dense: _vm.appConfig.forms.dense,
                                flat: _vm.appConfig.forms.flat,
                                solo: _vm.appConfig.forms.solo,
                                "solo-inverted":
                                  _vm.appConfig.forms.soloInverted,
                                filled: _vm.appConfig.forms.filled,
                                outlined: _vm.appConfig.forms.outlined
                              },
                              scopedSlots: _vm._u(
                                [
                                  !_vm.isChallengeDisabled &&
                                  _vm.challengeValue &&
                                  !_vm.readonly &&
                                  !_vm.formFieldDisabled
                                    ? {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-dialog",
                                              {
                                                attrs: {
                                                  fullscreen:
                                                    _vm.$vuetify.breakpoint
                                                      .smAndDown,
                                                  "max-width": "500",
                                                  persistent: "",
                                                  scrollable: ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "asyent-button",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  disabled: false,
                                                                  "button-icon":
                                                                    "mdi-shield-edit-outline",
                                                                  "button-tooltip":
                                                                    "Open Confirmation Code Dialog",
                                                                  icon: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.dialog,
                                                  callback: function($$v) {
                                                    _vm.dialog = $$v
                                                  },
                                                  expression: "dialog"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "mx-auto fill-height",
                                                    attrs: {
                                                      "max-width": "500"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-toolbar",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                          "max-height": "56"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-toolbar-title",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  large: "",
                                                                  left: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-shield-edit-outline "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " Confirmation Code "
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-spacer"),
                                                        !_vm.loading &&
                                                        _vm.tmpChallenge &&
                                                        _vm.tmpChallengeHash
                                                          ? _c(
                                                              "asyent-button",
                                                              {
                                                                attrs: {
                                                                  "button-icon":
                                                                    "mdi-check",
                                                                  "button-tooltip":
                                                                    "Save",
                                                                  icon: "",
                                                                  "requires-confirmation":
                                                                    ""
                                                                },
                                                                on: {
                                                                  confirmed:
                                                                    _vm.validate
                                                                }
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _c("asyent-button", {
                                                          attrs: {
                                                            "button-icon":
                                                              "mdi-close",
                                                            "button-tooltip":
                                                              "Cancel",
                                                            icon: "",
                                                            "requires-confirmation":
                                                              ""
                                                          },
                                                          on: {
                                                            confirmed:
                                                              _vm.cancel
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "text-justify"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "mt-5"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Please click on "
                                                            ),
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-shield-check-outline"
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " to send/resend the confirmation code to your email or mobile. Once received, type it in the box below and click on "
                                                            ),
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-check"
                                                              )
                                                            ]),
                                                            _vm._v(" : ")
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-text-field", {
                                                          staticClass: "mt-5",
                                                          attrs: {
                                                            "error-messages":
                                                              _vm.challengeErrors,
                                                            dense: "",
                                                            label:
                                                              "Please enter the confirmation code",
                                                            solo: "",
                                                            type: "text"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "prepend-inner",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        true
                                                                          ? _c(
                                                                              "asyent-button",
                                                                              {
                                                                                attrs: {
                                                                                  disabled:
                                                                                    _vm.loading,
                                                                                  loading:
                                                                                    _vm.loading,
                                                                                  "aria-label":
                                                                                    "Send/Resend Confirmation Code",
                                                                                  "button-icon":
                                                                                    "mdi-shield-check-outline",
                                                                                  "button-tooltip":
                                                                                    "Send/Resend Confirmation Code",
                                                                                  icon:
                                                                                    "",
                                                                                  "requires-confirmation":
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  confirmed:
                                                                                    _vm.issue
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.tmpChallenge,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.tmpChallenge = $$v
                                                            },
                                                            expression:
                                                              "tmpChallenge"
                                                          }
                                                        }),
                                                        _c("div", [
                                                          _vm._v(
                                                            " The confirmation code will be validated upon submission of the form. "
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    : null,
                                  {
                                    key: "append-outer",
                                    fn: function() {
                                      return [_vm._t("append-outer")]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.challengeValue,
                                callback: function($$v) {
                                  _vm.challengeValue = $$v
                                },
                                expression: "challengeValue"
                              }
                            },
                            "v-text-field",
                            _vm.$attrs,
                            false
                          ),
                          _vm.$listeners
                        )
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3803821775
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }