var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: { name: _vm.label, rules: _vm.formFieldRules, vid: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _c(
              "v-switch",
              _vm._g(
                _vm._b(
                  {
                    staticClass: "mt-2",
                    attrs: {
                      "aria-label": _vm.label,
                      disabled: _vm.formFieldDisabled,
                      "error-messages": _vm.formFieldErrors(errors)
                    },
                    model: {
                      value: _vm.value2,
                      callback: function($$v) {
                        _vm.value2 = $$v
                      },
                      expression: "value2"
                    }
                  },
                  "v-switch",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }