var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.noLabel ? _c("div", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
    !_vm.link && !_vm.showActivator
      ? _c("div", {
          staticClass: "form-display-text form-display-div",
          domProps: { innerHTML: _vm._s(_vm.printValue) }
        })
      : _vm._e(),
    _vm.link && !_vm.showActivator
      ? _c(
          "div",
          { staticClass: "form-display-div" },
          [
            _c(
              "router-link",
              { attrs: { to: _vm.link, tag: "a", target: "_blank" } },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: { "text-decoration": "unset" },
                    attrs: { color: "primary" }
                  },
                  [_vm._v("mdi-file-link")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "form-display-text form-display-link",
                    staticStyle: { color: "unset" }
                  },
                  [_vm._v(_vm._s(_vm.printValue))]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    !_vm.link && _vm.showActivator
      ? _c("div", { staticClass: "form-display-div" }, [
          _c(
            "span",
            _vm._g({ staticStyle: { cursor: "pointer" } }, _vm.$listeners),
            [
              _c(
                "v-icon",
                {
                  staticStyle: { "text-decoration": "unset" },
                  attrs: { color: "primary" }
                },
                [_vm._v("mdi-file-link")]
              ),
              _c(
                "span",
                {
                  staticClass: "form-display-text form-display-link",
                  staticStyle: { color: "unset" }
                },
                [_vm._v(_vm._s(_vm.printValue))]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }