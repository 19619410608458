var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.noHeader && !_vm.widget
        ? _c(
            "v-system-bar",
            {
              attrs: {
                color: _vm.$store.state.ui.maximizedShiftKey
                  ? "primary"
                  : _vm.appConfig.toolbar.transparentSystemBar
                  ? "transparent"
                  : "systemBar",
                dark: _vm.$store.state.ui.maximizedShiftKey,
                height: _vm.appConfig.toolbar.systemBarHeight,
                fixed:
                  _vm.appConfig.toolbar.fixedTools ||
                  _vm.$store.state.ui.maximizedShiftKey,
                app:
                  _vm.appConfig.toolbar.fixedTools ||
                  _vm.$store.state.ui.maximizedShiftKey
              }
            },
            [
              _vm.formActionsFiltered.length <= 7 &&
              _vm.$vuetify.breakpoint.mdAndUp
                ? _vm._l(_vm.formActionsFiltered, function(action) {
                    return _c("asyent-button", {
                      key: action["name"],
                      attrs: {
                        "button-icon": action["icon"],
                        "button-tooltip": action["tooltip"],
                        "requires-confirmation": action["action"],
                        small: "",
                        text: ""
                      },
                      on: {
                        confirmed: function($event) {
                          return _vm.formActionMethod(action)
                        }
                      }
                    })
                  })
                : _vm._e(),
              _vm.formActionsFiltered.length > 7 ||
              (_vm.formActionsFiltered.length > 0 &&
                _vm.$vuetify.breakpoint.smAndDown)
                ? _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        text: "",
                                        "aria-label": "Form Actions"
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-menu-down")
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "text-capitalize" },
                                      [_vm._v("Form Actions")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        103193617
                      )
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.formActionsFiltered, function(action) {
                          return _c(
                            "v-list-item",
                            { key: action.name, attrs: { dense: "" } },
                            [
                              _vm.isAuthorized(action)
                                ? _c("asyent-button", {
                                    key: action["name"],
                                    attrs: {
                                      "button-icon": action["icon"],
                                      "button-tooltip": action["tooltip"],
                                      "requires-confirmation": action["action"],
                                      small: "",
                                      text: ""
                                    },
                                    on: {
                                      confirmed: function($event) {
                                        return _vm.formActionMethod(action)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.toolbarActionsFiltered, function(action) {
                return _c(
                  "v-tooltip",
                  {
                    key: action.name,
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      "aria-label": action.tooltip,
                                      icon: !_vm.$vuetify.breakpoint.smAndUp,
                                      text: _vm.$vuetify.breakpoint.smAndUp
                                    },
                                    on: {
                                      click: function($event) {
                                        if (
                                          $event.ctrlKey ||
                                          $event.shiftKey ||
                                          $event.altKey ||
                                          $event.metaKey
                                        ) {
                                          return null
                                        }
                                        return _vm.$emit(
                                          "toolbar-action",
                                          action.name
                                        )
                                      }
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v(_vm._s(action.icon))]
                                  ),
                                  _vm.$vuetify.breakpoint.smAndUp
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "text-capitalize" },
                                          [_vm._v(_vm._s(action.tooltip))]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(_vm._s(action.tooltip))])]
                )
              }),
              _vm.headerActionsFiltered && _vm.headerActionsFiltered.length > 1
                ? _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: !_vm.$vuetify.breakpoint.smAndUp,
                                        "aria-label": "More Actions",
                                        text: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          left: _vm.$vuetify.breakpoint.smAndUp
                                        }
                                      },
                                      [_vm._v("mdi-menu")]
                                    ),
                                    _vm.$vuetify.breakpoint.smAndUp
                                      ? [
                                          _c(
                                            "span",
                                            { staticClass: "text-capitalize" },
                                            [_vm._v("More Actions")]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3743194984
                      )
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _vm._l(_vm.headerActionsFiltered, function(
                            action,
                            a
                          ) {
                            return [
                              "link" === action.type
                                ? [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: a,
                                        attrs: {
                                          to: _vm.headerLinkMethod(action.name),
                                          dense: ""
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(action.tooltip))
                                        ]),
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(action.icon))
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : "slink" === action.type
                                ? [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: a,
                                        attrs: { to: action.link, dense: "" }
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(action.icon))
                                            ])
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(action.tooltip))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: a,
                                        attrs: { dense: "" },
                                        on: {
                                          click: function($event) {
                                            if (
                                              $event.ctrlKey ||
                                              $event.shiftKey ||
                                              $event.altKey ||
                                              $event.metaKey
                                            ) {
                                              return null
                                            }
                                            return _vm.$emit(
                                              "header-action",
                                              action.name
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(action.icon))
                                            ])
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(action.tooltip))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.headerActionsFiltered &&
              _vm.headerActionsFiltered.length === 1
                ? _vm._l(_vm.headerActionsFiltered, function(action) {
                    return _c(
                      "v-tooltip",
                      {
                        key: action.name,
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            "link" === action.type
                              ? {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "aria-label": action.tooltip,
                                              icon: !_vm.$vuetify.breakpoint
                                                .smAndUp,
                                              text:
                                                _vm.$vuetify.breakpoint.smAndUp,
                                              to: _vm.headerLinkMethod(
                                                action.name
                                              )
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              }
                                            },
                                            [_vm._v(_vm._s(action.icon))]
                                          ),
                                          _vm.$vuetify.breakpoint.smAndUp
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(action.tooltip)
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                }
                              : "slink" === action.type
                              ? {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "aria-label": action.tooltip,
                                              icon: !_vm.$vuetify.breakpoint
                                                .smAndUp,
                                              text:
                                                _vm.$vuetify.breakpoint.smAndUp,
                                              to: action.link
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              }
                                            },
                                            [_vm._v(_vm._s(action.icon))]
                                          ),
                                          _vm.$vuetify.breakpoint.smAndUp
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(action.tooltip)
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                }
                              : {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "aria-label": action.tooltip,
                                              icon: !_vm.$vuetify.breakpoint
                                                .smAndUp,
                                              text:
                                                _vm.$vuetify.breakpoint.smAndUp
                                            },
                                            on: {
                                              click: function($event) {
                                                if (
                                                  $event.ctrlKey ||
                                                  $event.shiftKey ||
                                                  $event.altKey ||
                                                  $event.metaKey
                                                ) {
                                                  return null
                                                }
                                                return _vm.$emit(
                                                  "header-action",
                                                  action.name
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              }
                                            },
                                            [_vm._v(_vm._s(action.icon))]
                                          ),
                                          _vm.$vuetify.breakpoint.smAndUp
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(action.tooltip)
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(action.tooltip))])]
                    )
                  })
                : _vm._e(),
              _c("v-spacer"),
              _vm.appConfig.toolbar.resize &&
              !_vm.maximized &&
              !_vm.fullWidth &&
              !_vm.noWindowControls &&
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        "aria-label": "Resize",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          if (
                                            $event.ctrlKey ||
                                            $event.shiftKey ||
                                            $event.altKey ||
                                            $event.metaKey
                                          ) {
                                            return null
                                          }
                                          return _vm.resizeWindow($event)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-arrow-expand-horizontal")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3702090862
                      )
                    },
                    [_c("span", [_vm._v("Resize")])]
                  )
                : _vm._e(),
              _vm.appConfig.toolbar.maximize &&
              !_vm.standalone &&
              !_vm.fullWidth &&
              !_vm.noWindowControls &&
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        "aria-label": "Maximize/Minimize",
                                        icon: "",
                                        disabled: _vm.resize >= _vm.maxSize
                                      },
                                      on: {
                                        click: [
                                          function($event) {
                                            if (
                                              $event.ctrlKey ||
                                              $event.shiftKey ||
                                              $event.altKey ||
                                              $event.metaKey
                                            ) {
                                              return null
                                            }
                                            return _vm.transformWindow($event)
                                          },
                                          function($event) {
                                            if (!$event.shiftKey) {
                                              return null
                                            }
                                            return _vm.transformWindowShift(
                                              $event
                                            )
                                          }
                                        ]
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm.maximized
                                      ? _c("v-icon", [
                                          _vm._v("mdi-window-minimize")
                                        ])
                                      : _c("v-icon", [
                                          _vm._v("mdi-window-maximize")
                                        ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4229044958
                      )
                    },
                    [
                      _vm.maximized
                        ? _c("span", [_vm._v("Minimize")])
                        : _c("span", [
                            _vm._v(
                              "Maximize (Shift + Mouse Click for Extra Space)"
                            )
                          ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      !_vm.widget
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              (_vm.title && _vm.title.length > 0 && !_vm.noTitle) ||
              (!_vm.noBreadcrumbs && _vm.pagePath.length > 0)
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        _vm._b(
                          {
                            class: {
                              "ml-auto": _vm.centered,
                              "mr-auto": _vm.centered
                            }
                          },
                          "v-col",
                          _vm.columnsFiltered,
                          false
                        ),
                        [
                          _c(
                            "v-list",
                            {
                              attrs: {
                                color: "transparent",
                                dense: "",
                                flat: ""
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    {
                                      class: {
                                        "mb-6": _vm.$vuetify.breakpoint.smAndUp
                                      },
                                      attrs: {
                                        color: "primary",
                                        size: _vm.$vuetify.breakpoint.mdAndUp
                                          ? 80
                                          : 60
                                      }
                                    },
                                    [
                                      _vm.titleIcon && _vm.titleIcon.length > 0
                                        ? _c("v-icon", {
                                            attrs: {
                                              dark: "",
                                              "x-large":
                                                _vm.$vuetify.breakpoint.mdAndUp
                                            },
                                            domProps: {
                                              textContent: _vm._s(_vm.titleIcon)
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _vm.title &&
                                      _vm.title.length > 0 &&
                                      !_vm.noTitle
                                        ? _c("v-list-item-title", [
                                            _c(
                                              "h1",
                                              { staticClass: "text-h5" },
                                              [_vm._v(_vm._s(_vm.title))]
                                            )
                                          ])
                                        : _vm._e(),
                                      !_vm.noBreadcrumbs &&
                                      _vm.pagePath.length > 0 &&
                                      _vm.$vuetify.breakpoint.smAndUp
                                        ? _c(
                                            "v-list-item-subtitle",
                                            [
                                              _c("v-breadcrumbs", {
                                                staticClass: "pl-0",
                                                attrs: {
                                                  items: _vm.pagePath,
                                                  divider: "/"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    _vm._b(
                      {
                        class: {
                          "ml-auto": _vm.centered,
                          "mr-auto": _vm.centered
                        }
                      },
                      "v-col",
                      _vm.columnsFiltered,
                      false
                    ),
                    [_vm._t("default")],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.widget ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }