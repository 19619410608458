var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            "clipped-left":
              _vm.appConfig.navigation.clipped &&
              _vm.$vuetify.breakpoint.lgAndUp,
            app: "",
            color: "primary",
            dark: ""
          }
        },
        [
          !_vm.standalone &&
          _vm.$store.getters["menu/drawerMenu"].length > 0 &&
          !_vm.appConfig.navigation.permanent
            ? _c("v-app-bar-nav-icon", {
                attrs: { "aria-label": "Application Menu" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.setDrawer($event)
                  }
                }
              })
            : _vm._e(),
          _c(
            "router-link",
            {
              staticStyle: { height: "50px !important" },
              attrs: { to: "/", exact: "" }
            },
            [
              _vm.appConfig.toolbar.logo
                ? _c("img", {
                    staticClass: "ml-0 pl-0",
                    attrs: {
                      alt: "Application Icon",
                      height: "50",
                      width: "168",
                      src: require("@/assets/logo-toolbar.png")
                    }
                  })
                : _vm._e()
            ]
          ),
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-toolbar-title",
                { staticClass: "ml-0 pl-3 text-truncate text-no-wrap" },
                [_vm._v(" " + _vm._s(_vm.appConfig.toolbar.title) + " ")]
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.appConfig.toolbar.screen && _vm.$vuetify.breakpoint.smAndUp
            ? _c("asyent-button", {
                attrs: {
                  "button-icon": "mdi-fullscreen",
                  "button-tooltip": "Enter/Exit Full Screen",
                  icon: ""
                },
                on: { confirmed: _vm.toggleFullScreen }
              })
            : _vm._e(),
          _vm.appConfig.toolbar.theme
            ? _c("asyent-button", {
                attrs: {
                  "button-icon": _vm.isDark
                    ? "mdi-invert-colors"
                    : "mdi-invert-colors-off",
                  "button-tooltip": _vm.isDark
                    ? "Turn light mode"
                    : "Turn dark mode",
                  icon: ""
                },
                on: { confirmed: _vm.switchTheme }
              })
            : _vm._e(),
          _c(
            "v-menu",
            {
              attrs: {
                bottom: "",
                left: "",
                "offset-y": "",
                "aria-label": "Toolbar Menu"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _vm.$store.getters["toolbar/toolbarMenu"].length > 0
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon:
                                    !_vm.sso.authenticated() ||
                                    _vm.$vuetify.breakpoint.smAndDown,
                                  text:
                                    _vm.sso.authenticated() &&
                                    _vm.$vuetify.breakpoint.mdAndUp,
                                  ripple: "",
                                  "aria-label": "Open Toolbar Menu"
                                }
                              },
                              on
                            ),
                            [
                              _vm.sso.authenticated() &&
                              _vm.sso.name() &&
                              _vm.sso.username() &&
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.sso.nameWithInitials()
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    right:
                                      _vm.sso.authenticated() &&
                                      _vm.$vuetify.breakpoint.mdAndUp
                                  }
                                },
                                [_vm._v("mdi-dots-vertical")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                { attrs: { "max-width": "286" } },
                [
                  _c(
                    "v-list-item-group",
                    [
                      _vm.sso.authenticated() &&
                      _vm.sso.name() &&
                      _vm.sso.username()
                        ? _c(
                            "v-list-item",
                            { attrs: { ripple: false, inactive: "" } },
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticStyle: {
                                    "justify-content": "center !important"
                                  },
                                  attrs: {
                                    color: "secondary",
                                    size: "56",
                                    "max-width": "56"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.sso.initials()) + " ")]
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "pb-1 text-truncate" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.sso.nameWithInitials()) +
                                          " "
                                      ),
                                      _c("span", {
                                        staticClass: "caption",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.sso.username()
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _c("v-list-item-subtitle", {
                                    staticClass: "text-truncate subtitle-2",
                                    domProps: {
                                      textContent: _vm._s(_vm.sso.email())
                                    }
                                  }),
                                  _c("v-list-item-subtitle", {
                                    staticClass: "text-truncate subtitle-2",
                                    domProps: {
                                      textContent: _vm._s(_vm.sso.phone())
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.sso.authenticated() &&
                      _vm.sso.name() &&
                      _vm.sso.username()
                        ? _c("v-divider")
                        : _vm._e(),
                      _vm._l(
                        _vm.$store.getters["toolbar/toolbarMenu"],
                        function(item, i) {
                          return _c(
                            "v-list-item",
                            _vm._b(
                              { key: i, attrs: { exact: "" } },
                              "v-list-item",
                              item.target,
                              false
                            ),
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.name))
                              ])
                            ],
                            1
                          )
                        }
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }