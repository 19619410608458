var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      style: { opacity: _vm.appConfig.navigation.opacityDrawer },
      attrs: {
        clipped:
          _vm.appConfig.navigation.clipped && _vm.$vuetify.breakpoint.mdAndUp,
        floating: _vm.appConfig.navigation.floating,
        width: _vm.drawerWidth,
        app: "",
        permanent: _vm.appConfig.navigation.permanent,
        color:
          _vm.appConfig.navigation.transparentDrawer &&
          _vm.appConfig.navigation.fixed &&
          _vm.$vuetify.breakpoint.mdAndUp
            ? "transparent"
            : "drawer",
        temporary: !_vm.appConfig.navigation.fixed
      },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _vm.appConfig.navigation.showUserInfo
        ? _c(
            "v-sheet",
            {
              staticClass: "pt-2 pb-0 pl-4 pr-4",
              attrs: { color: "transparent" }
            },
            [
              _c(
                "v-list-item",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-list-item-avatar",
                    {
                      staticStyle: { "justify-content": "center !important" },
                      attrs: {
                        color: "secondary",
                        size: "56",
                        "max-width": "56"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.sso.initials()) + " ")]
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "pb-1 text-truncate" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.sso.nameWithInitials()) + " "
                          ),
                          _c("span", {
                            staticClass: "caption",
                            domProps: {
                              textContent: _vm._s(_vm.sso.username())
                            }
                          })
                        ]
                      ),
                      _c("v-list-item-subtitle", {
                        staticClass: "text-truncate subtitle-2",
                        domProps: { textContent: _vm._s(_vm.sso.email()) }
                      }),
                      _c("v-list-item-subtitle", {
                        staticClass: "text-truncate subtitle-2",
                        domProps: { textContent: _vm._s(_vm.sso.phone()) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("asyent-drawer-menu", {
        attrs: { "drawer-menu": _vm.drawerMenu, nav: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }