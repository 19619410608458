var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "asyent-card",
            _vm._b({}, "asyent-card", _vm.$attrs, false),
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4", lg: "3" } },
                            [
                              _c("asyent-form-display", {
                                attrs: {
                                  value: [
                                    _vm.value["documentPreviousStatus"],
                                    _vm.value["documentStatus"]
                                  ],
                                  label: "Status"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4", lg: "3" } },
                            [
                              _c("asyent-form-display", {
                                attrs: {
                                  value: _vm.value["documentCreatedOn"],
                                  "as-date-time": "",
                                  label: "Created At"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4", lg: "3" } },
                            [
                              _c("asyent-form-display", {
                                attrs: {
                                  value: _vm.value["documentUpdatedOn"],
                                  "as-date-time": "",
                                  label: "Updated At"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4", lg: "3" } },
                            [
                              _c("asyent-form-display", {
                                attrs: {
                                  value: _vm.value["documentUserId"],
                                  label: "Created/Updated By"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }